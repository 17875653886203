.input_1 {
  width: 200px;
}
.text_1 {
  font-size: 22px;
  font-weight: 600;
  color: red;
}
.text_2 {
  font-size: 14px;
}
.sh_1 {
  padding-left: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}
